import React from 'react';
import leaf from '../assets/svg/leaf.svg';

export default function HotelLeaves({ level, leaves, bucketlist }) {
  const getNumberOfLeaves = () => {
    return level; 
  };
  
  return (
    getNumberOfLeaves() > 0 ?(
      <div className={bucketlist ? 'tw bg-brand-altgreen py-1 px-2 rounded-3xl' : "tw bg-brand-altgreen py-2 px-4 rounded-3xl" }>
        <div className="flex space-x-2  pr-0.5">
          {[...Array(5)].map((_, index) =>
            index < getNumberOfLeaves() ? (
              <img className={bucketlist ? "h-4 w-2.5 " : "h-4 w-4"} src={leaf} alt="Full leaf icon" />
            ) : (
              <img
                className={bucketlist ? "h-4 w-2.5 opacity-30" : "h-4 w-4 opacity-30"}
                src={leaf}
                alt="Greyed out leaf icon"
              />
            ),
          )}
        </div>
      </div>
    ):(
      <div className={bucketlist ? 'tw py-4 px-2 rounded-3xl' : "tw py-4 px-4 rounded-3xl" }></div>
    )
  );
}
