import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { CircularProgressbar as RawCircularProgressbar } from 'react-circular-progressbar';
import { get, range, round } from 'lodash';
import 'react-circular-progressbar/dist/styles.css';
import AuthContext from 'contexts/auth';
import PageContext from 'contexts/page';
import { Buttons, ButtonReset, SubHeading, Heading, media, Button, Grid, Cell, PrimaryLink } from 'ui';
import { formatDate,oldFormatDate } from 'utils/date';
import { Link } from 'gatsby';
import Avatar from 'components/Avatar';
import PigIcon from '../assets/images/PigIcon';
import StarIcon from '../assets/images/StarIcon';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'constants';
import backgroundProfil from '../assets/images/backgroundProfil.jpg';
import HeartIconButton from 'components/HeartIconButton';
import { getLanguagePrefix } from '../utils';
import { Stack } from '@tymate/margaret';
import ReactTooltip from 'react-tooltip';
import HotelLeaves from 'components/HotelLeaves';
import { journeyFormatDate } from 'utils/date';
import { MdTrendingFlat } from 'react-icons/md';
import { getItemAsArray } from 'utils';

import defaultImage from '../assets/images/default-image.png'
const CashBack = styled.span`
  display: flex;
  justify-content: center;
  width: 4rem;
  background-color: #fcd6b2;
  color: #f9a24b;
  border-radius: 1rem;
  text-align: center;
  font-size: 0.9rem;
`;

const CustomTooltip = styled(ReactTooltip)`
  max-width: 300px;
  overflow-wrap: break-word;
  display: block;
  border-radius:50px !important;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, .5);
  text-align: center;
`;

const HotelWrapper = styled.div`
  position: relative;
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  ${media.mobile`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`}
  ${props =>
    props.isCarousel &&
    css`
      width: unset !important;
      margin-left: 15px;
      margin-right: 15px;
    `}

  ${props =>
    props.variant === 'map' &&
    css`
      background-color: #ffffff;
      box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      width: 200px;
    `}
`;

const CountryContent = styled.div`
  ${props =>
    props.variant === 'country' &&
    css`
      background: #f7f7f7;
      border-radius: 25px;
      padding-top: 30px;
      height: 288px;
      opacity: 1;
      ${props => props.city && css`
          height: 238px;
      `}

      ${props => props.random && css`
          height: 468px;
      `}
    `}
`;

const CountryPicture = styled.div`

  background-image: url(${props => props.destination ? props.imageUrl : `${API_URL}/${props.imageUrl}`});
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  transition: all 0.3s linear 0s;
  object-fit: contain;
  background-size:200px;
  background-position:center;
  &:hover {
    transform: scale(1.1);
  }


  ${props =>
    props.random &&
    css`
    width: 130px;
    height: 130px;
    margin-left: 18px;
    margin-bottom: 20px;
  `}
`;

const CityPicture = styled.div`

  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transition: all 0.3s linear 0s;
  object-fit: contain;
  background-size:200px;
  background-position:center;
  &:hover {
    transform: scale(1.1);
  }

  ${props =>
    props.random &&
    css`
    width: 130px;
    height: 130px;
    margin-left: 18px;
    margin-bottom: 20px;
  `}
`;

const PictureCard = styled.div`
  position: relative;
  background-image: url("${props => props.imageUrl}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: ${({ theme }) => theme.spacing()};
  border-radius: 4px;
  height: 350px;

  ${props =>
    props.bucketlist &&
    css`
      height: 215px;
    `}

  ${props =>
    props.variant === 'country' &&
    css`
      background-image: linear-gradient(
          230deg,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url("${props => props.imageUrl}");
      svg {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    `}

  ${props =>
    props.variant === 'discovery' &&
    css`
      height: 455px;
      background-image: linear-gradient(
        230deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5)
      ),
      url("${props => props.imageUrl}");
    `}


  ${props =>
    props.variant === 'article' &&
    css`
      height: 200px;
      border-radius: 6px 6px 0px 0px;
      background-image: linear-gradient(
          230deg,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url(${props.external ? props.imageUrl : `${API_URL}/${props.imageUrl}`});

      + * {
        padding-top: ${({ theme }) => theme.spacing()};
        max-width: 268px;

        text-align: left;
      }
    `}

  ${props =>
    props.flat &&
    css`
      max-height: 170px;
      &:hover {
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.3) 100%
          ),
          url("${backgroundProfil}");
        cursor: pointer;
      }
    `}

    ${props =>
      props.variant === 'map' &&
      css`
        border-radius: 4px 4px 0 0;
        height: 150px;
      `}

  ${props =>
    props.variant === 'explore' &&
    css`
      border-radius: 0;
      height: 130px;
      max-width: 300px;
      border-radius: 4px;
      background-image:  url("${props => props.imageUrl}");
    `}

  ${props =>
    props.variant === 'hotel' &&
    css`
      width: 100%;
      border-radius: 25px;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 60%
        ),
        url("${props => props.imageUrl}");
      
        &:hover {
          -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
      ${props =>
        props.variant === 'map' &&
        css`
          height: 100px;
        `}
    `}

  svg {
    color: ${({ theme }) => theme.text};
  }

  > * {
    position:absolute;
    width:auto;
    bottom:16px;
    right:16px;
    left:16px;
  }

`;
const HotelPictureWrapper = styled.div`
  overflow: hidden;
  border-radius: 25px;
  transform: translateZ(0); // Fix border radius on hover for safari
`;
const DescriptionWrapper = styled.div`
  padding-top: 0;
  max-height: 250px;
  text-align: left;

  > * {
    padding-top: ${({ theme }) => theme.spacing()};

    + * {
      padding-top: ${({ theme }) => theme.spacing(0.5)};
    }
  }

  ${props =>
    props.variant === 'article' &&
    css`
      padding: 0 ${({ theme }) => theme.spacing()}
        ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()};
      min-width: 100%;
      border-radius: 0 0 4px 4px;
    `}

  ${props =>
    props.variant === 'map' &&
    css`
      padding: ${({ theme }) => theme.spacing()};
      padding-top: 0;
      height: 110px;
    `}

    ${props =>
      props.variant === 'country' &&
      css`
        position: absolute;
        bottom: -60px;
        transform: translate(-50%, -50%);
        left: 50%;

        ${props =>
          props.random &&
          css`
             bottom: 40px;
          `}
      `}

      ${props =>
        props.variant === 'certification' &&
        css`
          position: absolute;
          bottom: -60px;
          transform: translate(-50%, -50%);
          left: 50%;
        `}
`;

export const Card = () => <PictureCard />;

const ContentCard = styled.div`
  display: block;
  align-items: center;
  border: 0;
  width: auto;
  background-color: #ffffff;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(0.5)};
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  ${props =>
    props.centered &&
    css`
      text-align: center;
      text-transform: uppercase;
    `}
`;

const TravelerContent = styled.div`
  color: ${({ theme }) => theme.textLighter};
`;

export const TravelerCard = ({ title, label, imageUrl }) => (
  <div>
    <PictureCard imageUrl={imageUrl}>
      <ContentCard centered>{title}</ContentCard>
    </PictureCard>
    <DescriptionWrapper>
      <TravelerContent>{label}</TravelerContent>
    </DescriptionWrapper>
  </div>
);

const CountryNumbers = styled.div`
  color: #6c6c6c;
  text-align: center;

  ${props =>
    props.random &&
    css`
      margin-top: -16px;
      margin-bottom: 20px;
  `}
`;

const CertificationLink = styled(Button)`
  color: #FFA24B;
  outline: none;
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  cursor: pointer;
`;

export const CertificationTitle = styled.h1`
  color: #1a1a1a;
  text-align: center;
  font-size: 14px;
  font: normal normal bold;
  ${props =>
    props.modal &&
    css`
      font-size: 16px;
      margin-top: 30px;
  `}
`;

export const CertificationPictureBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color : #fff;
  border-radius: 30%;
  width: 100px;
  height: 110px;

  ${props =>
    props.modal &&
    css`
      background-color : #f7f7f7;
  `}
`

export const CertificationPicture = styled.div`
  background-image: url("${props => props.imageUrl}");
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  transition: all 0.3s linear 0s;
  object-fit: contain;
  background-size: 70px;
  background-position:center;
  
`;

export const CertificationContainer = styled.div`
  background: #f7f7f7;
  border-radius: 25px;
  height: 258px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.modal &&
    css`
      height: 100%;
      background: #fff;
      margin-top: 50px;
      width: 100%;
    `}
`

const CountryHeading = styled.h1`
  color: #1a1a1a;
  text-align: center;
  font-size: 18px;
  font: normal normal bold;
  ${props =>
    props.city &&
    css`
      font-size: 14px;
  `}
  ${props =>
    props.random &&
    css`
      font-size: 20px;
      margin-top: -7px;
  `}
`;

export const CountryCard = ({ title, hotels, pictures, picture }) => {
  const { t } = useTranslation();
  const numberOfHotels =
    hotels.totalCount > 1
      ? t('country.hotelsCount_plural', { count: hotels.totalCount })
      : t('country.hotelsCount', { count: hotels.totalCount });

    var num = Math.floor(Math.random() * (pictures.length));
    const imageUrl = picture ? picture?.imagePath : pictures[num]?.mediumUrl;
  return (
    <div style={{ position: 'relative' }}>
      {imageUrl &&
        <CountryContent variant="country"/>
      }
      <DescriptionWrapper variant="country">
        <CountryPicture destination={!picture} imageUrl={imageUrl ? imageUrl : defaultImage}/>
        <CountryHeading>{title}</CountryHeading>
        <CountryNumbers>{numberOfHotels}</CountryNumbers>
      </DescriptionWrapper>
    </div>
  );
};

export const CityCard = ({hotels, attachments, title, slug, country, isRandom }) => {
  const { t } = useTranslation();
  let numberOfHotels = 0
  if(hotels){
    numberOfHotels =
    hotels.totalCount > 1
      ? t('country.hotelsCount_plural', { count: hotels.totalCount })
      : t('country.hotelsCount', { count: hotels.totalCount });
  }else{
    numberOfHotels = 0
  }
  const imageUrl = attachments[0].smallUrl;

  return (
    <div style={{ position: 'relative' }}>
      {imageUrl &&
        <CountryContent variant="country" city random= {isRandom}/>
      }
      <DescriptionWrapper variant="country" random = {isRandom}>
        <CityPicture  imageUrl={imageUrl} random= {isRandom}/>
        {isRandom ? 
        <PrimaryLink to={getLanguagePrefix(`${country.slug}/${slug}` )} cityCard>
          {t('utils.discoverAlso')}
        </PrimaryLink> : '' }
        <CountryHeading city random={isRandom}>{title}</CountryHeading>
        <CountryNumbers random={isRandom}>{numberOfHotels}</CountryNumbers>
        {isRandom ? 
        <Buttons>
        <Button
          variant="carnet"
          as={Link}
          to={getLanguagePrefix(
            `${country.slug}/${slug}`,
            )}
          >
          {t('home.logement.button')}
        </Button>
      </Buttons>  : '' 
      }
      </DescriptionWrapper>
    </div>
  );
};

export const CertificationCard = ({
  displayName,
  pictogram,
  setIsOpen,
  content,
  setContentShow,
  index
   }) => {

  const imageUrl = get(pictogram, 'url', '');
  const { t } = useTranslation();

  const openModal = (i) => {
    setIsOpen(true)
    setContentShow(i)
  }

  return (
    <>
    <CertificationContainer>
      <CertificationPictureBackground>
        <CertificationPicture imageUrl={imageUrl}/>
      </CertificationPictureBackground>
        <CertificationTitle>{displayName}</CertificationTitle>
        <CertificationLink  onClick={() => openModal(index)}>{ t('home.durabilit.button') }</CertificationLink>
    </CertificationContainer>
    </>
  );
};

const HotelStanding = styled.div`
  color: ${({ theme }) => theme.textLight};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const HotelAddress = styled.div`
  color: ${({ theme }) => theme.text};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  text-transform: uppercase;
  ${props =>
    props.resultPage &&
    css`
      color: #6c6c6c;
      margin-bottom: ${({ theme }) => theme.spacing(0.5)};
      text-transform: capitalize;
      ${props =>
        props.bucketlist &&
        css`
          font-size: 15px;
        `}
    `}
`;

const HotelInfos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;

const PigWrapper = styled.div`
  display: flex;
  background-color: rgba(255, 162, 75, 0.2);
  padding: 1px 6px;
  align-items: center;
  border-radius: 20px;
  max-width: auto;
`;

const Percentage = styled.p`
  color: #ffa24b;
  padding: 0;
  margin: 0;
  margin-left: ${({ theme }) => theme.spacing(0.25)};
  font-size: 14px;
  font-weight: 600;
`;

const PriceLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

const CircularSVG = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  height: 30px;
  top: 16px;
  left: 12px;
  z-index: 2;
`;

const CircularProgressbar = styled(RawCircularProgressbar)`
  max-width: 30px;
`;

const PercentageMatch = styled.div`
  color: #fff;
  white-space: nowrap;
  margin-left: 5px;
  font-weight: 600;
`;

const HeartIconWrapper = styled.div`
  position: absolute;
  right: 0px;
  left: unset;
`;

const Accreditation = styled.div`
  border-radius: 6px;
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  margin-left: ${({ theme }) => theme.spacing()};
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.tagStyles};
  background-color: ${({ theme }) => theme.tagStylesLight};
`;

const HotelLink = styled(Link)`
  text-decoration: none;
  z-index: 1;
  width: 100%;
`;

const ShowPrice = styled.span`
  color: ${({ theme }) => theme.text};
  font-weight: 700;
`;


export const HotelCard = ({
  variant,
  hasProgressBar,
  isCarousel,
  displayName,
  primaryAttachment,
  score,
  city,
  moneyPot,
  minPrice,
  minPricePerDay,
  sustainable,
  styles,
  stars,
  id,
  queryParams,
  slug,
  to,
  cashBackPercent,
  feelingoHotel,
  onHover,
  openInNewTab,
  hotelsSearch,
  openEngagement,
  bucketlist,
  destination
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { language } = useContext(PageContext);
  const imageUrl = primaryAttachment?.mediumUrl;
  let linkUrl = `${get(city, 'country.slug')}/${get(city, 'slug')}/${slug}${
    queryParams ? queryParams : ''
  }`;
  if (minPrice) {
    linkUrl += queryParams ? '&minPrice=' + minPricePerDay : '?minPrice=' + minPricePerDay;
  }
  let linkTo = to ? to : getLanguagePrefix(language, linkUrl);
  let onclick;
  if (openInNewTab) {
    linkTo = '#';
    onclick = event => {
      event.preventDefault();
      window.open(to ? to : getLanguagePrefix(language, linkUrl));
    };
  }
  let onClickEngagement;
  let linkToEngagements = to
    ? to
    : getLanguagePrefix(
        language,
        `${get(city, 'country.slug')}/${get(city, 'slug')}/${slug}${
          queryParams ? queryParams : ''
        }`,
      );
  if (openEngagement) {
    linkToEngagements = '#';
    onClickEngagement = event => {
      event.preventDefault();
      window.open(
        to
          ? to
          : getLanguagePrefix(
              language,
              `${get(city, 'country.slug')}/${get(city, 'slug')}/${slug}${
                queryParams ? queryParams + '#engagements' : ''
              }`,
            ),
      );
    };
  }
  return (
    <HotelWrapper
      variant={variant}
      isCarousel={isCarousel}
      onMouseEnter={Boolean(onHover) ? () => onHover(id) : undefined}
      onMouseLeave={Boolean(onHover) ? () => onHover(null) : undefined}
      hotelsSearch={hotelsSearch}
    >
      <HotelLink to={linkTo} onClick={onclick}>
        <HotelPictureWrapper>
        {hasProgressBar && (
          <CircularSVG>
            <CircularProgressbar
              value={score}
              strokeWidth={30}
              styles={{
                root: {},

                path: {
                  stroke: `rgba(115, 201, 146, 1)`,
                  strokeLinecap: 'butt',
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                  transform: 'rotate(0turn)',
                },
                trail: {
                  stroke: '#FFF',
                  strokeLinecap: 'butt',
                },
                text: {
                  fill: '#f88',
                  fontSize: '16px',
                },
                background: {
                  fill: '#73c992',
                },
              }}
            />
            <PercentageMatch>{Math.round(score || 100)}%</PercentageMatch>
          </CircularSVG>
        )}
          <PictureCard variant="hotel" imageUrl={imageUrl} bucketlist= {bucketlist}>
            
          </PictureCard>
        </HotelPictureWrapper>
      </HotelLink>
      <DescriptionWrapper variant={variant}>
        <HotelInfos>
          <div
            data-tip={
              t('hotel.score') + score + '<p></p>' + t('hotel.engagement')
            }
          >
            {variant !== 'map' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    width: 'auto',
                    cursor: 'pointer',
                  }}
                  to={linkToEngagements}
                  onClick={onClickEngagement}
                  variant="engagement"
                >
                  {sustainable && <HotelLeaves level={sustainable.level} bucketlist= {bucketlist} destination= {destination} />}
                </div>
              </div>
            )}
          </div>
          {isAuthenticated && (
            <HeartIconWrapper>
              <HeartIconButton hotelId={id} />
            </HeartIconWrapper>
          )}
          <HotelLink to={linkTo} onClick={onclick}>
            <Heading resultPage variant={variant} bucketlist= {bucketlist}>
              {displayName ? displayName : 'undefined'}
            </Heading>
            <HotelAddress resultPage bucketlist={bucketlist}>
              {city
                ? `${city.title}, ${get(city, 'country.title', '')}`
                : 'undefined'}
            </HotelAddress>

            <PriceLine>
              {moneyPot && (
                <PigWrapper>
                  <PigIcon size={20} />
                  <Percentage>50%</Percentage>
                </PigWrapper>
              )}
            </PriceLine>
            {!bucketlist && !destination ? 
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                  <HotelStanding>
                    {minPrice && (
                      <Stack gutterSize={1.5} marginBottom={0.5}>
                        <ShowPrice>
                          {`À partir de ${round(minPrice, 0)}€`}
                        </ShowPrice>
                        {Boolean(cashBackPercent) && (
                          <>
                            <div
                              style={{display: 'flex', alignItems: 'end', marginLeft: '50px' }}
                              data-tip={t('hotel.cashBack.title', {
                                count: cashBackPercent,
                              })}
                              data-for="tooltip"
                            >
                              <CashBack>
                                <Stack gutterSize={0.5} alignY="center">
                                  <PigIcon size={20} />
                                  {`${cashBackPercent}%`}
                                </Stack>
                              </CashBack>
                            </div>
                            <CustomTooltip
                              id="tooltip"
                              effect="solid"
                              delayHide={100}
                              delayShow={100}
                              type="light"
                              html={true}
                              border={false}
                              multiline={true}
                              place="bottom"
                            />
                          </>
                        )}
                      </Stack>
                    )}
                    <div>
                      {stars && range(0, stars).map(() => <StarIcon size={20} />)}
                    </div>
                  </HotelStanding>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {styles && (
                      <Accreditation>{styles[0]?.displayName}</Accreditation>
                    )}
                  </div>
              </div>
              : ''
              }
          </HotelLink>
        </HotelInfos>
      </DescriptionWrapper>
    </HotelWrapper>
  );
};

const BookingInfo = styled.div`
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 30px;
  margin-bottom: 28px;
  padding: ${({ theme }) => theme.spacing()};
`;

const HotelPic = styled.div`
  background-image: url("${props => props.image}");
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 195px;
  width: 90%;
  margin-top: 10px;
  margin-left: 10px;
`;

const TitleCard = styled.div`
  font-weight: 700;
  font-size: 11px;
  color: #212121;
  text-transform: uppercase;
`;

const LightContent = styled.div`
  color: #6C6C6C;
  > p {
    margin-top: 4px;
  }
`;

const WrapperInfos = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  div:nth-child(2n + 1) {
    margin-top: ${({ theme }) => theme.spacing()};
  }
  ${media.mobile`
    margin-left: 6%;
  `}
`;

const HotelName = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
  ${media.mobile`
    margin-left: 6%;
  `}
`;

const HotelAdress = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-top: -13px;
  margin-bottom: 30px;
  ${media.mobile`
    margin-left: 6%;
  `}
`;

export const HotelBookingCard = ({
  hotel,
  startAt, 
  endAt,
  koobData,
  to,
}) => {
  const { t } = useTranslation();
  const imageUrl = hotel.primaryAttachment?.mediumUrl;
  const hotelRooms = getItemAsArray(koobData.response.data.bookItem.roomsPlanBooked.room);

  return (
    <HotelLink to={to}>
      <BookingInfo>
        <Grid>
          <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
            <HotelPic image={imageUrl} alt="" />
          </Cell>
          <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
            <HotelName> {hotel.displayName ? hotel.displayName : 'undefined'}</HotelName>
            <HotelAdress>
              {hotel.city
                  ? `${hotel.city.title}, ${get(hotel.city, 'country.title', '')}`
                  : 'undefined'}
            </HotelAdress>
            <WrapperInfos>
              <TitleCard>{t('hotel.dates.stay')}</TitleCard>
              <LightContent>{journeyFormatDate(
                  startAt,
                  )}  <MdTrendingFlat size={40} color={'#67CB8B'}/>  {journeyFormatDate(endAt)}
              </LightContent>
                <>
                  <TitleCard>{t('hotel.room.title')}</TitleCard>
                  <LightContent>
                    {hotelRooms.map(room =>(
                      <p style={{color:'#6C6C6C'}}>{room.roomDescription}</p>
                    ))}
                  </LightContent>
                </>
            </WrapperInfos>
          </Cell>
        </Grid>
      </BookingInfo>  
    </HotelLink>
  );
};


const ArticleDescription = styled.div`
  font: normal normal 600 16px/25px Muli;
  letter-spacing: -0.16px;
  color: #6c6c6c;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const ArticleAuthor = styled.div`
  color: #1a1a1a;
  font-weight: 700;
  text-align: left;
`;

const ArticleThemeContent = styled.div`
  display:inline-block;
  padding-left :15px;
  padding-right :15px;
  height: 29px;
  border-radius: 15px;
  padding-top: 0px;
  ${props =>
    props.variant==="home" &&
    css`
    background-color: #f7f7f7 ;
    `}
  ${props =>
    props.variant==="article" &&
    css`
    background-color: #fff !important;
    `}
`;

const ArticleTheme = styled.span`
  text-transform: uppercase;
  text-align: left;
  color: #6c6c6c;
  font: normal normal 800 10px/25px Muli;

  ${media.mobile`
    font: normal normal 800 10px/25px Muli;
  `}
`;

const ArticlePicture = styled.div`
  position: relative;
  background-image: url("${props => props.imageUrl}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: ${({ theme }) => theme.spacing()};
  height: 230px;
  transform-origin: 0 0;
  transition: transform .25s, visibility .25s ease-in;

  ${props =>
    props.variant === 'article' &&
    css`
      border-radius: 25px 25px 0px 0px;
      background-image: linear-gradient(
          230deg,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url(${props.external ? props.imageUrl : `${API_URL}/${props.imageUrl}`});

      + * {
        padding-top: ${({ theme }) => theme.spacing()};
        width: 100%;
        text-align: left;
      }
    `}

  svg {
    color: ${({ theme }) => theme.text};
  }

  > * {
    position:absolute;
    width:auto;
    bottom:16px;
    right:16px;
    left:16px;
  }

`;

const ArticleWrapper = styled.div`
  transition: all 150ms ease;
  height: 435px;
  border-radius: 25px;
  overflow: hidden;
  &:hover ${DescriptionWrapper} {
    transform: translateY(-40px);
    transition: all 0.3s;
  }

  &:hover ${ArticlePicture} {
    transform: scale(1.05);
  }

  &:hover ${Buttons} {
    opacity: 1;
    display: block;
  }
`;

export const ArticleCard = ({
  publishedAt,
  displayName,
  excerpt,
  picture,
  slug,
  thematic,
  external,
  background,
  variant,
}) => {
  const { t } = useTranslation();

  return (
    <ArticleWrapper style={{ backgroundColor: background }}>
      <ArticlePicture
        variant="article"
        external={external}
        imageUrl={get(picture, 'imagePath') || picture}
      />
      <DescriptionWrapper
        variant="article"
        style={{ backgroundColor: background }}
      >
         <ArticleThemeContent variant={variant} >
          <ArticleTheme>{thematic.displayName}</ArticleTheme>
        </ArticleThemeContent>
        <ArticleAuthor>{displayName}</ArticleAuthor>
        <ArticleDescription>{excerpt}</ArticleDescription>
        <Buttons variant="article">
          <Button
            article
            variant="carnet"
            as={Link}
            to={getLanguagePrefix(`${t('url.articles')}/${slug}`)}
          >
            {t('profiles.travelerProfile.cards.read')}
          </Button>
        </Buttons>
      </DescriptionWrapper>
    </ArticleWrapper>
  );
};

const ArticleDate = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textLighter};
`;

const TalkAboutUsAsPrimaryLink = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
  transition: color 150ms ease;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
`;

const ArticleTalkAboutUsAuthor = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: 700;
`;


const ArticleTalkAboutUsDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const ArticleTalkAboutUsWrapper = styled.div`
  transition: all 150ms ease;
  ${props =>
  props.isCarousel &&
  css`
      margin-left: 15px;
      margin-right: 15px;
    `}

  &:hover {
    box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.2);
  }
`;

const DescriptionTalkAboutUsWrapper = styled.div`
  padding-top: 0;
  max-height: 250px;
  overflow: hidden;
  text-align: left;

  > * {
    padding-top: ${({ theme }) => theme.spacing()};

    + * {
      padding-top: ${({ theme }) => theme.spacing(0.5)};
    }
  }

  ${props =>
  props.variant === 'article' &&
  css`
      padding: 0 ${({ theme }) => theme.spacing()}
        ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()};
      background-color: #fff;
      min-width: 100%;
      border-radius: 0 0 4px 4px;
    `}
`;

export const ArticleTalkAboutUsCard = ({
                              publishedAt,
                              displayName,
                              excerpt,
                              isCarousel,
                              picture,
                              external,
                            }) => {
  const { t } = useTranslation();
  return (
    <ArticleTalkAboutUsWrapper isCarousel={isCarousel}>
      <PictureCard
        variant="article"
        external={external}
        imageUrl={get(picture, 'imagePath') || picture}
      />
      <DescriptionTalkAboutUsWrapper variant="article">
        <ArticleDate>{oldFormatDate(publishedAt)}</ArticleDate>
        <ArticleTalkAboutUsAuthor>{displayName}</ArticleTalkAboutUsAuthor>
        <ArticleTalkAboutUsDescription>{excerpt}</ArticleTalkAboutUsDescription>
        <TalkAboutUsAsPrimaryLink>
          {t('profiles.travelerProfile.cards.read')}
        </TalkAboutUsAsPrimaryLink>
      </DescriptionTalkAboutUsWrapper>
    </ArticleTalkAboutUsWrapper>
  );
};

export const ExploreCard = ({ imageUrl }) => (
  <div>
    <PictureCard variant="explore" imageUrl={imageUrl} />
  </div>
);

const WrapperCard = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: 298px;
  height: 228px;
  padding: ${({ theme }) => theme.spacing()};
  text-align: center;
  align-items: center;

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  :hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
`;

const ProfileLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
`;

const ProfileCompletion = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textLighter};
`;

const ProfileCardTitle = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: 18px;
  font-weight: 500;
`;

const CompletionRateCircle = styled.div``;

export const ProfilCard = ({ isCreate, displayName, completionRate, id }) => {
  const { t } = useTranslation();
  return (
    <>
      {isCreate ? (
        <ProfileLink to="/profile-walkthrough">
          <WrapperCard>
            <ProfileCardTitle>
              {t('profiles.travelerProfile.card.new')}
            </ProfileCardTitle>
            <Buttons>
              <Button variant="secondary">Créer</Button>
            </Buttons>
          </WrapperCard>
        </ProfileLink>
      ) : (
        <ProfileLink to={`/${t('url.account')}/${t('url.profile')}/${id}`}>
          <WrapperCard>
            <ProfileCardTitle>{displayName}</ProfileCardTitle>
            {/*<ProfilType>Solo Traveler</ProfilType>*/}
            <CompletionRateCircle>
              <CircularProgressbar
                value={round(completionRate, 0)}
                strokeWidth={10}
                styles={{
                  root: {},
                  path: {
                    stroke: `rgba(115, 201, 146, 1)`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0turn)',
                  },
                  trail: {
                    stroke: '#FFF',
                    strokeLinecap: 'butt',
                  },
                  text: {
                    fill: '#f88',
                    fontSize: '16px',
                  },
                  background: {
                    fill: '#73c992',
                  },
                }}
              />
            </CompletionRateCircle>
            <ProfileCompletion>
              {round(completionRate, 0)}% complété
            </ProfileCompletion>
            {round(completionRate, 0) > 100 && (
              <Buttons>
                <Button variant="outline">compléter</Button>
              </Buttons>
            )}
          </WrapperCard>
        </ProfileLink>
      )}
    </>
  );
};

const QualityWrapper = styled(ButtonReset)`
  display: block;
  background-position: center center;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 4px solid transparent;
  transition: border 150ms ease;
  height: 276px;

  h3 {
    letter-spacing: 0.6px;
    line-height: ${({ theme }) => theme.spacing(2)};
  }

  svg {
    margin-right: auto;
    margin-left: auto;

    ${media.mobile`
    width: 90px;
    height: 90px;
  `}
  }

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) =>
    theme.spacing()};
  `}

  ${media.mobile`
    margin-bottom: 75px;
  `}
`;

const BackIcon = styled.div`
  background-color: #f7f7f7;
  width: 140px;
  padding: 35px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 44px;

  ${media.mobile`
    width: 180px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 44px;
  `}
`;
export const QualityCard = ({ icon, title, url, slug, style }) => {
  return (
    <QualityWrapper as={Link} to={url} state={{ slug: slug }}>
      <BackIcon>{icon}</BackIcon>
      <SubHeading style={style} variant="quality">
        {title}
      </SubHeading>
    </QualityWrapper>
  );
};

const ReviewWrapper = styled.div`
  display: flex;
  /*max-width: 40em;*/
  > * {
    margin-bottom: ${({ theme }) => theme.spacing()};
  }
`;

const ReviewContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
  font-size: 16px;
  font-weight: 400;
`;

const AuthorName = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: 700;
`;

const ReviewDate = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.textLighter};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const AvatarWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const AuthorLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StarsLine = styled.div`
  display: flex;
`;

const StarsCriteria = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textLight};
  text-align: right;
`;

export const ReviewCard = ({
  content,
  stars,
  updatedAt,
  firstName,
  lastName,
  profile,
  isMyReview,
}) => {
  const { t } = useTranslation();
  return (
    <ReviewWrapper>
      <AvatarWrapper>
        {!isMyReview && profile ? (
          <Avatar small name={profile.displayName} />
        ) : (
          <Avatar small firstName={firstName} lastName={lastName} />
        )}
      </AvatarWrapper>
      <div style={{ width: '100%' }}>
        <ReviewDate>{formatDate(updatedAt)}</ReviewDate>
        <AuthorLine>
          <div>
            {!isMyReview && profile ? (
              <AuthorName>{profile.displayName}</AuthorName>
            ) : (
              <AuthorName>
                {firstName} {lastName}
              </AuthorName>
            )}
          </div>
          <div style={{ TextAlign: 'right' }}>
            <StarsLine>
              {range(0, stars).map(() => (
                <StarIcon size={20} />
              ))}
            </StarsLine>
            <StarsCriteria>
              {t('profiles.reviews.myReviews.criteria')}
            </StarsCriteria>
          </div>
        </AuthorLine>
        <ReviewContent>{content}</ReviewContent>
      </div>
    </ReviewWrapper>
  );
};
