export const getPaginationParams = pagination => {
  const { size, number } = pagination || {};
  let params = {};
  if (size || number) {
    params = {
      ...params,
      'page[size]': size || 20,
      'page[number]': number || 1,
    };
  }
  return params;
};
