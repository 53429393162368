import React from 'react';
import Icon from 'react-icon-base';

const StarIcon = props => (
  <Icon viewBox="0 0 26 26" {...props}>
    <path
      d="M0 8.786l5.603 5.506c.248.24.362.595.303.935L4.583 23l6.926-3.67c.307-.163.675-.163.982 0L19.416 23l-1.322-7.773c-.059-.34.055-.694.303-.935L24 8.786l-7.743-1.134c-.344-.05-.64-.269-.795-.58L12 0 8.537 7.071c-.154.312-.45.532-.794.581L0 8.786z"
      fill="#ffd440"
      fillRule="evenodd"
    />
  </Icon>
);

export default StarIcon;
