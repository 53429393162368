import { get, post, FEELINGO_API, put, getPaginated, getSearch } from './';
import { getPaginationParams } from './utils';

export const getSelectedHotel = ({ language }) =>
  get('/api/v1/hotels/feelingo_selection', { lang: language }, FEELINGO_API);

export const getHotels = ({ language, size, number, options }) =>
  getPaginated('/api/v1/koob/hotels', {
    lang: language,
    options: {
      ...getPaginationParams({ size, number }),
      lang: language,
      includes: [
        'primary_attachment',
        'city',
        'city.country',
        'sustainable',
        'official_categories',
        'styles',
        'stars',
        'hotel_categories',
        'hotel_descriptions',
        'hotel_descriptions.description',
      ],
      ...options,
    },
  });

// export const searchHotels = ({ language, size, number, ...params }) =>
//   get('/api/v1/koob/hotels/search_with_score', params);

export const searchHotels = async ({ lang,search }) => {
  const result = await getSearch(
    `/api/v1/koob/hotels/search_with_score`,{
      lang,
      search:{
        ...search,
        includes: [
          'primary_attachment',
          'city',
          'city.country',
          'sustainable',
          'official_categories',
          'styles'
        ]
      },
    }
  );
  return result;
}

export const getHotel = ({ id, language }) =>
  get(`/api/v1/koob/hotels/${id}`, {
    lang: language,
    options: {
      lang: language,
      includes: [
        'primary_attachment',
        'city',
        'sustainable',
        'styles',
        'stars',
      ],
    },
    includes: ['translations'],
  });

export const getHotelComments = ({ hotelId }) =>
  get(
    `/api/v1/hotels/${hotelId}/comments`,
    { includes: 'profile' },
    [],
    FEELINGO_API,
  );

export const searchKoediaDetails = ({ search }) =>
  post('/api/v1/koob/hotels/search', { search });

export const getKoediaRateDetails = ({ rateDetails }) =>
  post('/api/v1/koob/hotels/rate_details', { rateDetails });

export const getHotelActualities = ({ id }) =>
  get(`/api/v1/hotels/${id}/actualities`);

export const getProfileFacilitiesInclude = ({ id }) =>
  get(`/api/v1/profiles/${id}/facilities/include`);

export const getProfileFacilitiesExclude = ({ id }) =>
  get(`/api/v1/profiles/${id}/facilities/exclude`);

export const likeHotel = ({ hotelId }) =>
  put(`/api/v1/user/hotel/toogle_like`, { user: { id: hotelId } });

export const getHotelsLiked = () => get(`/api/v1/user/hotel/liked`);
