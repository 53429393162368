import React from 'react';

const HeartFullIcon = props => (
  <svg width={27} height={27} {...props}>
    <g id="Favorite_icon" data-name="Favorite icon" transform="translate(0.75 0.75)">
    <path id="fav-icon" d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z" transform="translate(-2.323 -4.497)" fill="#67cb8b" stroke="#67cb8b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
  </g>
  </svg>
);

export default HeartFullIcon;
